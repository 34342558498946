// extracted by mini-css-extract-plugin
export var mvpWebDevBenefitsSection = "B_dM";
export var mvpWebDevBusinessTypeSection = "B_dJ";
export var mvpWebDevCaseSection = "B_dQ";
export var mvpWebDevClientQuotes = "B_dR";
export var mvpWebDevExpertiseSection = "B_dP";
export var mvpWebDevIndustriesSection = "B_dH";
export var mvpWebDevPracticesSection = "B_dN";
export var mvpWebDevPrimeSection = "B_dF";
export var mvpWebDevProcessSection = "B_dK";
export var mvpWebDevServicesSection = "B_dG";
export var mvpWebDevTechStackSection = "B_dL";